
.div-ov-hidden {
  width: 105%;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
  background-color: #ec8d00;
  /* margin-top: -19px;
  margin-left:-8px;
  margin-right:-6px;
  margin-bottom: 2px; */
  }

.animated-text {
    display: inline-block;
    /* color: #FFF; */
    padding-left: 100%;
    animation: move 60s linear infinite;
    font-weight: bold;
    color: white;
  }

  @keyframes move {
    0%   { transform: translate(0, 0); }
    100% { transform: translate(-100%, 0); }
}

.div-ov-hidden-mobile {
  width: 110%;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
  background-color: #ec8d00;
  margin-left:-16px;
  margin-top: -28px;
  margin-bottom: 12px;
  /* margin-top: -19px;
  margin-left:-8px;
  margin-right:-6px;
  margin-bottom: 2px; */
  }

  .button-link {
    height: 60px;
    font-size: 20px
  }