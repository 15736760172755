@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
//@import '~bootswatch/dist/lux/variables';

$modal-fade-transform: scale(0.6);
$body-bg: #f1f8f1;
$theme-colors: (
  'primary': #118000,
  'danger': #ff4136,
);

//$font-size-base: 1.2rem; // this changes the font-size throughout bootstrap
//$font-family-sans-serif: 'Lato', Arial, sans-serif;
//$line-height-base: 1.8;

$dropdown-bg: $dark;
$dropdown-link-color: $light;

@import '~bootstrap/scss/bootstrap';
//@import '~bootswatch/dist/lux/bootswatch';

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  /* we don't want any of these to scroll */
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.transparent-modal .modal-dialog .modal-content {
  background-color: transparent;
  border: none;
}

.transparent-card .card {
  background-color: transparent;
}

.tooltip-inner {
  max-width: 350px; /* set this to your maximum fitting width */
  width: inherit; /* will take up least amount of space */
  text-align: left;
  border-radius: 8px;
}

.button-link {
  display: flex;
  height: 80px;
  cursor: pointer;
  font-size: 20px;
}

.button-link:hover {
  background-color: #e7e6e6;
  font-weight: bold;
}